<template>
  <div class="meeting">
    <!--  新增九宫格界面-->
    <div class="top">
      <p class="title">新建会议</p>
      <span>免费版会议有效期为30天，到期之后访问微网站将提示会议已过期，如需延长有效期请联系平台客服。</span>
    </div>
    <div class="content">
      <div class="content-steps">
        <a-steps :current="current">
          <a-step title="填写会议信息" />
          <a-step title="选择首页模版" >
            <admin_con v-if="selectId" slot="icon" style="font-size: 35px;" type="icon-wancheng" />
          </a-step>
          <a-step title="完成" />
        </a-steps>
      </div>
      <div v-if="current === 0" class="meetingInformation">
        <a-form-model
          style="width: 100%"
          ref="refForm"
          layout="inline"
          :model="add_Form"
          :rules="addFormRules"
        >
          <div style="display: flex; justify-content: space-around">
            <div style="width: 600px">
              <a-form-model-item label="会议KV图" style="margin-left: 10px">
                <div style="position: relative;display: flex">
                  <div class="upImgModel" @click="openImgModel('coverPics')">
                    <div class="contents" v-if="!add_Form.coverPics[0]">
                      <div class="addModel">+</div>
                      <div>上传</div>
                    </div>
                    <div>
                      <img
                        style="
                          width: 280px;
                          border-radius: 10px;
                          margin-top: 1%;
                          margin-bottom: 1%;
                        "
                        :src="add_Form.coverPics[0]"
                        alt=""
                      />
                    </div>
                  </div>
                  <div style="height: 165px;" v-if="psdKv"><div @click="downKvPsd" class="upPsd">下载kv源文件 <a-icon type="vertical-align-bottom" /></div></div>
                  <!--                <a-icon v-if="add_Form.coverPics[0]"-->
                  <!--                        @click="()=>{add_Form.coverPics = []}"-->
                  <!--                        class="icon" type="close-circle"/>-->
                </div>
                <div style="color: #a1a1a1; font-size: 12px">
                  注:建议尺寸1920px*1080px，大小不超过2M
                </div>
              </a-form-model-item>

              <div style="display: flex; width: 600px">
                <a-form-model-item label="首页背景图">
                  <div style="position: relative">
<!--                     @click="openImgModel('homeCoverImage')" 自定义上传图片-->
                    <div class="upImgModel2">
                      <div class="contents2" v-if="!add_Form.homeCoverImage">
                        <div class="addModel2" style="margin-top: 30px">+</div>
                        <div>上传</div>
                      </div>
                      <div
                        style="width: 100%; height: 100%"
                        v-if="add_Form.homeCoverImage"
                      >
                        <img
                          style="
                            border-radius: 10px;
                            margin-top: 2px;
                            width: 96%;
                            height: 96%;
                          "
                          :src="add_Form.homeCoverImage"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="hoverBgc">
                      <div class="iconDelDiv">
                        <div class="iconDel-cont">
                          <div class="customUpload" @click="openImgModel('homeCoverImage')">自定义上传</div>
                          <div class="customUpload" @click="openSystemBGI">选择系统背景</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="color: #a1a1a1; font-size: 12px">
                    注:大小不超过500kb
                  </div>
                </a-form-model-item>

                <a-form-model-item label="转发封面图">
                  <div style="position: relative">
                    <div class="upImgModel2" @click="openImgModel('relayImg')">
                      <div class="contents2" v-if="!add_Form.relayImg">
                        <div class="addModel2" style="margin-top: 30px">+</div>
                        <div>上传</div>
                      </div>
                      <div
                        style="width: 100%; height: 100%"
                        v-if="add_Form.relayImg"
                      >
                        <img
                          style="
                            border-radius: 10px;
                            margin-top: 2px;
                            width: 96%;
                            height: 96%;
                          "
                          :src="add_Form.relayImg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="hoverBgc" v-if="add_Form.relayImg">
                      <div class="iconDel" >
                        <admin_con
                          @click="
                            () => {
                              add_Form.relayImg = '';
                            }
                          "
                          type="icon-shanchu1"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="color: #a1a1a1; font-size: 12px">
                    注:可上传logo，尺寸建议500*500
                  </div>
                  <div
                    style="color: #a1a1a1; font-size: 12px; margin-top: -15px"
                  >
                    无转发封面图时，默认转发封面图为会议kv图
                  </div>
                </a-form-model-item>
              </div>
            </div>
            <div style="width: 600px">
              <a-form-model-item
                label="会议分类"
                class="formModel"
                style="margin-left: 45px"
              >
                <a-select
                  show-search
                  option-filter-prop="children"
                  @change="columIdChange"
                  style="width: 300px; float: left"
                  class="input"
                  v-model="add_Form.columId"
                  placeholder="默认分类"
                >
                  <a-select-option
                    v-for="lives in liveClassifyList"
                    :value="lives.id"
                  >
                    {{ lives.title }}
                  </a-select-option>
                </a-select>
                <!--              <div>-->
                <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
                <a-icon @click="loadList" class="iconStY" type="plus-square" />
                <!--              </div>-->
              </a-form-model-item>

              <a-form-model-item prop="name" label="会议名称" class="formModel">
                <a-textarea
                  v-model="add_Form.name"
                  style="width: 300px"
                  placeholder="请输入"
                />
                <div style="color: #a1a1a1; font-size: 12px">
                  注：会议名称最长不能超过60个汉字
                </div>
              </a-form-model-item>

              <a-form-model-item prop="time" label="会议日期" class="formModel">
                <a-range-picker
                  :disabled-date="disabledDate"
                  v-model="add_Form.time"
                  style="width: 300px"
                  @calendarChange="DateChange"
                  @change="changePriceRangeDate"
                />
              </a-form-model-item>

              <a-form-model-item
                prop="address"
                label="会议地点/描述"
                style="margin-left: 2px; margin-bottom: 10px"
              >
                <a-input
                  style="width: 300px"
                  v-model="add_Form.address"
                  placeholder="请输入"
                />
                <div style="color: #a1a1a1; font-size: 12px">
                  注：该内容将显示在转发之后的消息中
                  <a
                    @click="
                      () => {
                        this.visiblePic = true;
                      }
                    "
                    >查看示例</a
                  >
                </div>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
        <a-divider />
        <div class="bottomText">
          <!--        <p style="font-weight: bold;">说明</p>-->
<!--          <div style="font-weight: bold; margin-bottom: 5px">说明</div>-->
          <div style="font-weight: bold; margin-bottom: 5px">内容审核</div>
          <span>依据国家相关法律犯规，严禁上传发布包含色情、暴力、违法、侵权等性质内容，违规内容将会进行下架、删除等处理。</span>
          <div style="font-weight: bold; margin-bottom: 5px">版权说明</div>
          <span>您对所上传的内容（包括但不限于图片、元素、文字、链接等）版权负责，平台无法对您上传内容的合法性负责。若是产生侵权等，您将承担相应的责任。</span>

        </div>
      </div>
      <div v-if="current === 1" class="templated">
        <a-spin :spinning="spin">
          <div class="templated-img">
            <div style="display: flex;justify-content: center">
<!--              <a-icon style="color: #9c9b9b;font-size: 20px" type="check-circle" />-->
              <admin_con style="font-size: 25px" type="icon-icon_duihao-mian"/>
              <div style="margin-left: 5px;font-size: 16px"><span style="color: red">*</span> 系统模板</div>
            </div>
<!--            <div style="display: flex;height: 35px;line-height: 35px;">-->
<!--              <div>会议状态：</div>-->
<!--              <a-radio-group v-model="liveStatus" button-style="solid" @change="liveStatusChange">-->
<!--                <a-radio-button value="">-->
<!--                  全部-->
<!--                </a-radio-button>-->
<!--                <a-radio-button value="free">-->
<!--                  免费-->
<!--                </a-radio-button>-->
<!--                <a-radio-button value="paid">-->
<!--                  付费-->
<!--                </a-radio-button>-->
<!--              </a-radio-group>-->
<!--            </div>-->
          </div>
          <a-row>
            <!-- 空数据 -->
            <div v-if="datasource.length == 0">
              <NullDataPrompt message="暂无数据"></NullDataPrompt>
            </div>
            <a-col
              v-else
              :span="innerWidths > 0 && innerWidths < 1920 ? 5 : 4"
              v-for="item in datasource"
              :key="item.id"
            >
              <div
                :style="{
                  width: innerWidths > 0 && innerWidths < 1920 ? '92%' : '90%',
                }"
                class="iconSty"
              >
                <img
                  :class="{ imgSelect: selectId === item.id }"
                  @click="selectTemplate(item)"
                  :src="item.egImg"
                  class="img"
                />
<!--                <div>-->
<!--                  <a-tag :color="item.name === '模版一（九宫格）'?'green':item.name === '模版二（方块格）'?'blue':''" class="tag">{{ item.name }}</a-tag>-->
<!--                </div>-->
                <img v-if="item.mode === 'free'" class="pitchOn" src="../../../assets/paidImg/free.png">
                <img v-if="item.mode === 'paid'" class="pitchOn" src="../../../assets/paidImg/paid.png">
              </div>
            </a-col>
          </a-row>

          <div style="width: 98%;margin: auto;display: flex;">
            <admin_con style="font-size: 25px" type="icon-icon_duihao-mian"/>
            <div style="margin-left: 5px;font-size: 16px">选择图标</div>
          </div>

          <div style="margin-top: 10px;margin-left: 40px;position: relative" >
            <div class="XTemplate" @click="openModel">
              <span v-if="!previewObj.previewImg">+选择图标</span>
              <img v-if="previewObj.previewImg" class="XTemplate-img" :src="previewObj.previewImg" alt="">
            </div>
            <div class="hoverBgc" v-if="previewObj.previewImg">
              <div class="iconDel">
                <admin_con
                    @click="()=>{this.previewObj = {}}"
                    type="icon-shanchu1"/>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <div v-if="current === 2" class="modelSty">
        <admin_con style="font-size: 90px" type="icon-tongguo" />
        <div class="message">操作成功</div>
        <div style="margin-top: 100px">
          <a-button @click="returnBtns">返回会议列表</a-button>
          <a-button
            @click="setLiveCode"
            type="primary"
            style="margin-left: 30px"
            >设置会议内容</a-button
          >
        </div>
      </div>
    </div>
    <div class="bottom"></div>
    <div class="footerBtn"></div>
    <!-- 底部   -->
    <div class="bottomSty" v-if="current === 0 || current === 1">
      <div class="btn">
        <a-button v-if="current === 0" @click="returnBtn">取消并返回</a-button>
        <a-button
          v-if="current === 0"
          style="margin-left: 20px"
          @click="submitSave"
          type="primary"
          >
          {{selectId ?'提交':'保存并继续'}}
        </a-button>
        <a-button v-if="current === 1" @click="skipContinue"
          >跳过并继续</a-button>
        <a-button
          v-if="current === 1"
          style="margin-left: 20px"
          @click="submitBtn"
          type="primary"
          >提交</a-button>
      </div>
    </div>
    <!------------------图片对话框----------------------->
    <pictureDialog
      :info="pInfo"
      :visible="visible"
      v-on:closeMain="closeMain"
      v-on:checkList="checkList"
    >
    </pictureDialog>
    <PicModel
      v-on:picModelSwitch="picModelSwitch"
      :pic-val="picVal"
      :visible="visiblePic"
    >
    </PicModel>

    <SystemIcons
        v-if="systemIconsVisible"
        :visible="systemIconsVisible"
        v-on:closeMainSystemIcons="closeMainSystemIcons"
        v-on:checkListSystemIcons="checkListSystemIcons"
    ></SystemIcons>
    <!-- 新增对话框 -->
    <a-modal
      title="新增"
      :visible="visibleClass"
      @ok="handleOk"
      @cancel="handleCancel"
      style="width: 200px; height: 130px"
    >
      <a-form-model
        :model="form"
        :rules="addRules"
        ref="ruleForm_add"
        style="display: flex"
      >
        <a-form-model-item label="分类标题" prop="name" style="display: flex">
          <a-input
            placeholder="请输入"
            v-model="form.name"
            style="display: flex; margin-top: 5px; width: 220px"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--    系统背景图-->
    <BackgroundImage
        v-if="BackgroundImageVisible"
        :visible="BackgroundImageVisible"
        v-on:closeMainBackgroundImage="closeMainBackgroundImage"
        v-on:checkListBackgroundImage="checkListBackgroundImage"
    />

  </div>
</template>

<script>
import PictureDialog from "@/components/Material/pictureDialog";
import moment from "moment";
import { liveClassify } from "@/service/MedicalConference";
import {
  getTemplateTitle,
  postConvention, postConventionKf,
} from "@/service/medicalConference_api";
import { AddManagement, getPage } from "@/service/columnmanageapi";
import NullDataPrompt from "@/components/NullDataPrompt";
import { admin_icon } from "@/utils/myIcon";
import { selectConventionTemp } from "@/service/authentication";
import PicModel from "@/components/Authentication/PicModel";
import SystemIcons from "@/components/meet/SystemIcons";
import BackgroundImage from "@/components/meet/BackgroundImage";

export default {
  name: "Meeting",
  components: {
    BackgroundImage,
    SystemIcons,
    PictureDialog,
    NullDataPrompt,
    admin_con: admin_icon,
    PicModel,
  },
  data() {
    return {
      systemIconsVisible:false,
      BackgroundImageVisible:false, //系统背景图
      liveStatus:'',
      offsetDays: 86400000 * 6, //最多选择7天
      tooltip: "用于首页的直播倒计时",
      tooltipAddress: "显示在转发之后的消息摘要处",
      current:0,
      liveClassifyList: [
        {
          id:0,
          title:'默认分类'
        }
      ],
      visibleClass: false,
      form: {},
      template_list: [],
      psdKv:'',
      add_Form: {
        coverPics: [
          "https://cos.yichimeeting.com/images_convention/4438eb00-70fc-4084-8dab-8b9302dae545.png",
        ],
        homeCoverImage: "https://cos.yichimeeting.com/images_convention/f2cfa8f3-7490-4572-a298-fad7da64d1c0.png",
        relayImg: "",
        columId: 0,
        name: "",
        time: [],
        address: "",
      },
      addRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      addFormRules: {
        name: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 1,
            max: 60,
            message: "会议名称最长不能超过60个汉字",
            trigger: "blur",
          },
        ],
        time: [{ required: true, message: "请选择", trigger: "change" }],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        // coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
        // startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        // columId: [{ required: true, message: '请选择', trigger: 'change' }],
        // template: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      visible: false,
      visiblePic: false,
      picVal:require('@/assets/icon/toolIcon.png'),
      spin: false,
      pInfo: true,
      datasource: [],
      picList: [],
      curPage: 1,
      total: 0,
      pageSize: 10,
      innerWidths: null,
      selectId: null,
      openModelType: null,
      conferenceCode: null,
      conferenceId: null,
      selectPriceDate: "",
      select: {},
      isKefu:null,
      userId:null,
      previewObj:{},
    };
  },
  created() {
    const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    this.userId = userInfo.id;
    this.isKefu = userInfo.isKefu;
    if (sessionStorage.getItem("pageParams")) {
      let pageParams = JSON.parse(sessionStorage.getItem("pageParams"));
      this.add_Form.homeCoverImage = pageParams.bgImg;
      // this.add_Form.relayImg = pageParams.egImg;
      let add = [];
      add.push(pageParams.kvImg);
      this.add_Form.coverPics = add;
      this.psdKv = pageParams.psdKv;
    }
    this.liveClassifyRow();
    this.innerWidths = window.innerWidth; //屏幕宽度
    (this.pageSize = this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12),
      this.load();
    this.$nextTick(() => {
      if (this.$route.query.selectId) {
        this.selectId = parseInt(this.$route.query.selectId);
        // this.isShowIconTitle = parseInt(this.$route.query.isShowIconTitle);
      }
    });
  },
  methods: {
    openModel(){
      this.systemIconsVisible = true;
    },
    liveStatusChange(){
      this.load();
    },
    moment,
    returnBtn() {
      this.$store.dispatch("isShowNav", true); //导航栏
      window.history.back();
    },
    returnBtns() {
      this.$store.dispatch("isShowNav", true); //导航栏
      this.$router.push({ path: "meetingManagement" });
    },
    setLiveCode() {
      this.$store.dispatch("meetCode", this.conferenceCode);
      // this.$store.dispatch("isShowIconTitle", this.isShowIconTitle ? 1:0);
      this.$store.dispatch("isShowIconTitle", 1);
      this.$router.push({
        path: "/featureManagement",
        query: { id: this.conferenceId },
      });
    },
    submitSave() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let form = this.add_Form;
          let data;
          if(this.isKefu){
          //  客服
            data = {
              address: form.address, //会议地址
              beginDate: moment(form.time[0]).format("YYYY-MM-DD"), //会议日期-开始
              endDate: moment(form.time[1]).format("YYYY-MM-DD"), //会议日期-结束
              domainCode: "yichimeeting", //写死传过来
              coverPics: form.coverPics.length ? form.coverPics : ["https://cos.yichimeeting.com/images_convention/4438eb00-70fc-4084-8dab-8b9302dae545.png",], //封面图
              homeCoverImage: form.homeCoverImage, //背景图
              relayImg: form.relayImg, //转发封面图
              title: form.name, //会议标题
              columId: form.columId, //会议所属分类id
              tempUrl: "/conference", //选择模板的链接

              isKfCreated:1, //客服创建
              createdKfId:this.userId, //客服id
              byId:this.$route.query.memberId //客服创建会议用户id
            };
            this.post_conventionKf(data);
          }
          else if(!this.isKefu){
          //  用户
            data = {
              address: form.address, //会议地址
              beginDate: moment(form.time[0]).format("YYYY-MM-DD"), //会议日期-开始
              endDate: moment(form.time[1]).format("YYYY-MM-DD"), //会议日期-结束
              domainCode: "yichimeeting", //写死传过来
              coverPics: form.coverPics.length ? form.coverPics : ["https://cos.yichimeeting.com/images_convention/4438eb00-70fc-4084-8dab-8b9302dae545.png",], //封面图
              homeCoverImage: form.homeCoverImage, //背景图
              relayImg: form.relayImg, //转发封面图
              title: form.name, //会议标题
              columId: form.columId, //会议所属分类id
              tempUrl: "/conference", //选择模板的链接

              byId:this.userId //用户自己id
            };
            this.post_convention(data);
          }


        } else {
          return false;
        }
      });
    },
    submitBtn() {
      if (this.selectId) {
        this.selectConventionT(this.selectId, this.conferenceCode);
      } else {
        this.$message.warning("请先选择模板！");
      }
    },
    //新增医学会议列表 -- 用户
    async post_convention(data) {
      const response = await postConvention(data);
      if (response.code === 0) {
        this.$message.success("新增成功~");

        this.conferenceCode = response.data.conferenceCode;
        this.conferenceId = response.data.id;

        if(this.$route.query.selectId){ //判断若是在模板跳转过来的新增自动添加成功
          await this.selectConventionT(this.selectId, response.data.conferenceCode);
        }else {
          this.current = 1;
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    //新增医学会议列表 -- 客服
    async post_conventionKf(data) {
      const response = await postConventionKf(data);
      if (response.code === 0) {
        this.$message.success("新增成功~");

        this.conferenceCode = response.data.conferenceCode;
        this.conferenceId = response.data.id;

        if(this.$route.query.selectId){ //判断若是在模板跳转过来的新增自动添加成功
          await this.selectConventionT(this.selectId, response.data.conferenceCode);
        }else {
          this.current = 1;
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    //选择首页模板
    async selectConventionT(tempId, conferenceCode) {
      let data = {
        icontempId:this.previewObj.iconTemplateId ? this.previewObj.iconTemplateId : null
      }
      const response = await selectConventionTemp(tempId, conferenceCode,data);
      if (response.code === 200) {
        this.current = 2;
      } else {
        this.$message.warning(response.message);
      }
    },
    skipContinue() {
      this.selectId = null;
      this.current = 2;
    },
    selectTemplate(item) {
      this.selectId = item.id;
      this.isShowIconTitle = item.isShowIconTitle;
    },
    load() {
      this.spin = true;
      getPage({
        isOpen: 1,
        mode:this.liveStatus,
        curPage: this.curPage,
        pageSize: this.pageSize,
      }).then((res) => {
        this.datasource = res.data;
        this.total = res.count;
        this.spin = false;
      });
    },
    closeMain(val) {
      this.visible = val;
    },
    closeMainSystemIcons(val) {
      this.systemIconsVisible = val;
    },
    closeMainBackgroundImage(val) {
      this.BackgroundImageVisible = val;
    },
    picModelSwitch(val) {
      this.visiblePic = val;
    },
    checkList(val) {
      this.picList = val[0].url;
      let type = this.openModelType;
      if (type === "coverPics") {
        // 封面图
        this.add_Form.coverPics[0] = this.picList;
      } else if (type === "homeCoverImage") {
        //背景图
        this.add_Form.homeCoverImage = this.picList;
      } else if (type === "relayImg") {
        //转发封面图
        this.add_Form.relayImg = this.picList;
      }
    },
    checkListSystemIcons(val){
      this.previewObj = val;
    },
    checkListBackgroundImage(val){
      this.add_Form.homeCoverImage = val;
    },
    openImgModel(type) {
      this.visible = true;
      this.openModelType = type;
    },
    openSystemBGI(){
      this.BackgroundImageVisible = true;
    },
    downKvPsd(){
      window.location.href = this.psdKv;
    },
    // 选择会议日期
    DateChange(date, dateString) {
      this.selectPriceDate = date[0];
    },
    //根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledDate(current) {
      if (this.selectPriceDate) {
        let selectV = moment(this.selectPriceDate, "YYYY-MM-DD").valueOf();
        return (
          current > moment(new Date(selectV + this.offsetDays), "YYYY-MM-DD") ||
          current < moment(new Date(selectV - this.offsetDays), "YYYY-MM-DD")
        );
      } else {
        return false;
      }
    },
    //选择完时间 清空限制
    changePriceRangeDate(date, dateString) {
      this.add_Form.time = dateString;
      this.selectPriceDate = "";
    },
    StartChange(date, dateString) {
      this.add_Form.startTime = dateString;
    },
    columIdChange(val) {
      this.add_Form.columId = val;
    },
    async liveClassifyRow() {
      this.liveClassifyList = [
        {
          id:0,
          title:'默认分类'
        }
      ];

      let data = {
        pageNo: 1,
        pageSize: 9999999,
        type: "meeting",
      };
      const response = await liveClassify(data);
      if (response.code === 0) {
        // this.liveClassifyList = response.data;
        let list = response.data;
        for (let i=0;i<list.length;i++){
          this.liveClassifyList.push(list[i]);
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    loadList() {
      this.visibleClass = true;
    },
    colorAdd(e) {
      this.add_Form.mainColor = e.target.value;
    },
    handleOk() {
      this.$refs.ruleForm_add.validate((valid) => {
        if (valid) {
          let data = {
            title: this.form.name,
            type: "meeting",
          };
          this.postClass(data);
          this.form.name = "";
          this.visibleClass = false;
        }
      });
    },
    async postClass(data) {
      const response = await AddManagement(data);
      if (response.code === 200) {
        this.$message.success("新增成功！");
        this.add_Form.columId = response.data;
        await this.liveClassifyRow();
      } else {
        this.$message.warning(response.message);
      }
    },
    handleCancel() {
      this.visibleClass = false;
      this.form.name = '';
    },
    //  获取列表 --- 模板列表
    async getTemList() {
      const Info = await getTemplateTitle();
      if (Info.code === 0) {
        for (let i = 0; i < Info.data.length; i++) {
          if (Info.data[i].isOpen === 1) {
            this.template_list.push({
              id: Info.data[i].id,
              isBanner: Info.data[i].isBanner,
              isBgimg: Info.data[i].isBgimg,
              isNotice: Info.data[i].isNotice,
              isOpen: Info.data[i].isOpen,
              name: Info.data[i].name,
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.meeting {
  .top {
    height: 100px;
    padding: 15px 0 0 20px;
    border-left: 1px solid #edeff2;
    border-top: 1px solid #edeff2;
    .title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 20px;
    }
  }
  .content {
    min-height: 800px;
    border: 10px solid #edeff2;
    .content-steps {
      width: 90%;
      margin: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .meetingInformation {
      width: 90%;
      margin: auto;
      .formModel {
        margin-left: 35px;
        margin-bottom: 10px;
      }
      .bottomText {
        color: #909090;
      }
    }
    .templated {
      width: 91.5%;
      margin: auto;
      height: auto;
      margin-bottom: 60px;
      .templated-img{
        display: flex;justify-content: space-between;width: 98%;margin: auto;margin-bottom: 10px;
      }
      .XTemplate{
        cursor: pointer;
        border: 1px #c4c4c4 dashed;width: 200px;height: 200px;line-height: 200px;text-align: center;color: #868686;position: relative;
      }
      .XTemplate-img{
        width: 100%;height: 100%;position: absolute;top: 0;left: 0;
      }
      .hoverBgc{
        width: 200px;height: 100%;
        position: absolute;
        top:0;
        left: 0;
        background-color: rgba(0,0,0,.4);
        opacity: 0; //透明度为0 不显示遮罩层
        display: flex;
        border-radius: 0;
        justify-content: center;
        justify-items: center;
        font-size: 25px;
        .iconDel{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .hoverBgc:hover{
        opacity: 1;
      }
      .iconSty {
        margin: auto;
        height: 350px;
        margin-bottom: 30px;
        position: relative;
        .tag {
          position: absolute;
          left: 5px;
          top: 1%;
        }
        .img {
          width: 100%;
          margin: auto;
          height: 100%;
          display: flex;
          border: 1px solid #F5F5F5;
          justify-content: center;
        }
        .pitchOn{
          position: absolute;
          top: 0;
          right: 0;
          width: 60px;
          height: auto;
        }
        .img:hover {
          border: 1px solid #1890ff;
        }
        .imgSelect {
          border: 3px solid #1890ff;
          box-shadow: 5px 5px 5px #63caff;
        }
      }
    }
    .modelSty {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 80px;
      .message {
        font-size: 25px;
        font-weight: bold;
        color: rgb(0, 0, 0, 0.85);
        margin-top: 20px;
      }
      .timeMess {
        color: #929292;
        margin-top: 15px;
      }
    }
    .iconStY {
      color: #45a5e6;
      font-size: 20px;
      margin-left: 5px;
      margin-top: 3px;
      float: left;
    }
    .upPsd{
      margin-top: 120px;margin-left: 10px;color: #45A5E6;cursor: pointer;
    }
    .upImgModel {
      width: 288px;
      min-height: 165px;
      text-align: center;
      background-color: #fcfcfc;
      border: #dddddd 2px dashed;
      border-radius: 10px;

      .contents {
        font-size: 18px;
        text-align: center;
        width: 100%;
        margin-top: 50px;
        color: #9c9b9b;
        .addModel {
          font-size: 50px;
        }
      }
    }
    .upImgModel2 {
      width: 150px;
      height: 150px;
      text-align: center;
      background-color: #fcfcfc;
      border: #dddddd 2px dashed;
      border-radius: 10px;
      .contents2 {
        font-size: 18px;
        text-align: center;
        width: 100%;
        color: #9c9b9b;
        .addModel2 {
          font-size: 50px;
        }
      }
    }
    .hoverBgc {
      width: 150px;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      top: 0;
      //z-index: 999;
      border-radius: 10px;
      opacity: 0; //透明度为0 不显示遮罩层
      display: flex;
      justify-content: center;
      justify-items: center;
      font-size: 25px;
      .iconDel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .hoverBgc:hover {
      opacity: 1;
    }
  }
  .bottom {
  }
  .footerBtn {
  }
}
.bottomSty {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  background-color: #f0f2f5;
  border-bottom: 1px solid #fff;
  .btn {
    width: 85%;
    display: flex;
    justify-content: right;
    margin-top: 10px;
  }
}
.iconDelDiv{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  .iconDel-cont{
    width: 100%;
    padding: 5%;
    font-size: 11px;
    color: #F5F5F5;
    display: flex;
    justify-content: space-between;
  }
  .customUpload{
    cursor: pointer;
  }
}

</style>
